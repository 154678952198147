import 'core-js/stable' ;
import Vue from "vue" ;
import CreateApp from "./app" ;
import { Store } from "vuex" ;
import {
    BigBigWork,
    CreateCrossDomain,
    GuideManager,
    keepCacheInit,
    SearchProxy,
    CacheHistory,
    AuthWeixinAppID,
    CreateProxy, crossDomainMessage, CrossDomainData
} from "bbw-common" ;
import { fetchWXConfig } from "@scripts/mp";
import { GuideConfig } from "@scripts/GuideConfig" ;
import { AnalysisInit } from "@scripts/Analysis" ;
import Router from "vue-router" ;
import { Icon } from 'vant' ;
import Vue2TouchEvents from 'vue2-touch-events' ;
// import "bbw-m-components/lib/assets/layout.css";

// @ts-ignore
const wx = require ( `weixin-js-sdk` ) ;
window.wx = wx ;
// wx.config ( {
//     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//     appId: AuthWeixinAppID, // 必填，公众号的唯一标识
//     timestamp: ``, // 必填，生成签名的时间戳
//     nonceStr: ``, // 必填，生成签名的随机串
//     signature: ``, // 必填，签名，见附录1
//     jsApiList: [] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
// } ) ;

Vue.use ( Icon ) ;
Vue.use ( Vue2TouchEvents, {
    disableClick: true
} ) ;

keepCacheInit () ;

// 禁止系统自带的下拉等功能
document.body.addEventListener ( `touchmove`, function ( e ) {
    e.preventDefault () ;
}, { passive: false } ) ;
// 屏蔽从左边划出返回的功能
document.body.addEventListener ( `touchstart`, function ( e ) {
    const left = e.changedTouches[0].pageX ;
    const bounce = window.innerWidth / 750 * 60 ;
    if ( left < bounce ) {
        e.preventDefault () ;
    }
}, { passive: false } ) ;

// 阻止双指放大
document.addEventListener ( `gesturestart`, ( event ) => {
    event.preventDefault () ;
} ) ;

/** asyncData请求初始化 */
Vue.mixin ( {
    beforeRouteUpdate ( to, from, next ) {
        console.dir ( wx ) ;
        // eslint-disable-next-line no-undef
        _hmt.push ( [`_trackPageview`, to.path] ) ;
        console.log ( `beforeRouteUpdate` ) ;
        const { asyncData } = this.$options ;
        if ( asyncData ) {
            asyncData ( {
                store: this.$store,
                route: to
            } ).then ( next ).catch ( next ) ;
        } else {
            next () ;
        }
    },
    beforeRouteEnter ( to, from, next ) {
        next ( vm => {
            if ( vm.$store.state.isWechatBrowser ) {
                fetchWXConfig ().then ( res => {

                } ).catch ( e => { console.warn ( e ) ; } ) ;
            }
            console.log ( `beforeRouteEnter` ) ;
            // eslint-disable-next-line no-undef
            _hmt.push ( [`_trackPageview`, to.path] ) ;
        } ) ;
    }
} ) ;
const handleCrossDomain = () => {
    if ( window.crossDomainReady ) {
        console.log ( `跨域iframe加载成功` ) ;
        window.setTimeout(async () => {
            const [err, isIncognito] = await crossDomainMessage<string> ( `GetCookie`, `crossdomain`, `ReturnGetCookie_crossdomain`, 300 ) ;
            console.log('isIncognitoisIncognitoisIncognitoisIncognito', isIncognito)
            CrossDomainData.ready = true ;
            if ( !err && isIncognito ) {
                CrossDomainData.isIncognito = false ;
            } else {
                CrossDomainData.isIncognito = true ;
            }
            BigBigWork.emit ( `CROSS_DOMAIN_READY` ) ;
        }, 200)
    }else{
        console.log ( `跨域iframe加载失败` ) ;
        CrossDomainData.ready = true ;
        CrossDomainData.isIncognito = false ;
        BigBigWork.emit ( `CROSS_DOMAIN_FAIL` ) ;
    }
}
/** 初始化客户端前调用 */
const beforeInitClient = async function ( app, store:Store<any>, router ) {
    /** 初始化axios请求拦截 */
    try {
        if ( __DEV__ && !console.dir ) {
            console.dir = ( msg ) => {
                console.log ( JSON.stringify ( msg ) ) ;
            } ;
        }
        // 初始化统计系统
        AnalysisInit () ;
        // 初始化代理
        await CreateProxy () ;
        // 开始跨域
        await CreateCrossDomain ().catch ( e => { throw e ; } ) ;
        // SearchProxy.serverList.push('47.99.187.194:8211');
        store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        if ( window.crossDomainReady !== undefined ) {
            handleCrossDomain();
        }else{
            const iframe = document.getElementById('crossDomain');
            iframe.addEventListener('load', ( ) => {
                window.crossDomainReady = true;
                handleCrossDomain();
            })
            iframe.addEventListener('error', ( ) => {
                window.crossDomainReady = false;
                handleCrossDomain();
            })
        }
        // 事件监听
        window.addEventListener ( `resize`, () => {
            store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;

/**
 * 修改 vue-router 的push方法
 */
const originalPush = Router.prototype.push ;
// @ts-ignore
Router.prototype.push = function push ( location, onResolve, onReject ) {
    if ( onResolve || onReject ) {
        return originalPush.call ( this, location, onResolve, onReject ) ;
    }
    return originalPush.call ( this, location ).catch ( err => err ) ;
} ;

/** 创建客户端 */
const CreateEntryClient = () => {
    try {
        const { app, router, store } = CreateApp () ;
        if ( window.__INITIAL_STATE__ ) {
            store.replaceState ( window.__INITIAL_STATE__ ) ;
        }
        router.onReady ( async () => {
            // Add router hook for handling asyncData.
            // Doing it after initial route is resolved so that we don't double-fetch
            // the data that we already have. Using router.beforeResolve() so that all
            // async components are resolved.
            router.beforeResolve ( ( to, from, next ) => {
                const matched = router.getMatchedComponents ( to ) ;
                const prevMatched = router.getMatchedComponents ( from ) ;
                let diffed = false ;
                const activated = matched.filter ( ( c, i ) => {
                    return diffed || ( diffed = ( prevMatched[i] !== c ) ) ;
                } ) ;
                const asyncDataHooks = activated.map ( c => c.asyncData ).filter ( _ => _ ) ;
                if ( !asyncDataHooks.length ) {
                    return next () ;
                }

                // bar.start()
                Promise.all ( asyncDataHooks.map ( hook => hook ( { store, route: to } ) ) )
                    .then ( () => {
                        // bar.finish()
                        next () ;
                    } )
                    .catch ( next ) ;
            } ) ;
            // 初始化客户端
            await beforeInitClient ( app, store, router ) ;
            // 挂载客户端DOM
            app.$mount ( `#app` ) ;
            // pwa设置
            const pwaFlag = window.localStorage.getItem ( `pwa` ) ;
            pwaFlag && store.commit ( `setPWA`, true ) ;
            // pwa通知栏显示
            if ( !pwaFlag ) {
                const showPWAInstall = localStorage.getItem ( `showPWAInstall` ) ;
                if ( showPWAInstall ) {
                    store.commit ( `setShowPWAInstall`, JSON.parse ( showPWAInstall ) ) ;
                }
            }
            // 初始化引导系统
            GuideManager.init ( GuideConfig, store ) ;
            // 环境设为客户端
            store.commit ( `setIsClient` ) ;
            store.commit ( `updateBrowserEnv` ) ;
            store.commit ( `updateOSEnv` ) ;
            // service worker
            if ( location.protocol === `https:` && navigator.serviceWorker ) {
                navigator.serviceWorker.register ( `/service-worker.js` ).then ( reg => {
                    console.dir ( `Service Worker 注册成功` ) ;
                } ).catch ( e => {
                    console.dir ( `Service Worker 注册失败` ) ;
                } ) ;
            }
            // 监听pwa安装成功事件
            window.addEventListener ( `beforeinstallprompt`, ( e ) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault () ;
                window.deferredPrompt = e ;
            } ) ;
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
// service worker
// if ('https:' === location.protocol && navigator.serviceWorker) {
//   navigator.serviceWorker.register('/service-worker.js')
// }

CreateEntryClient () ;

export default CreateEntryClient ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept ( ) ;
}
