<template>
  <div ref='view' :class="['view', blur? 'blurPage' :'']" :id=" state === `activated` ? `view` : `` "
       @touchstart="handleTouchStart" @touchend="handleTouchEnd"
  >
    <transition>
      <keep-cache>
        <router-view
            ref="activity"
            :key="$store.state.route.path + `?` + key"
            :name="state === `activated` ? `default` : `deactivated`"
            :scroll-dom="dom"
            :scroll-y="freeze ? 0 : scroll.y"
            :scroll-x="scroll.x"
            :direction="direction"
            :freeze="freeze"
        />
      </keep-cache>
    </transition>
    <sticky-bar v-if="$store.getters['UserInfo/userReady'] && $route.meta.showNavBar && !$store.state.isFocusInput"
                :direction="direction" position="bottom" :scroll-y="scroll.y"
                @ChangeDirectionVisible="handleChangeDirectionVisible">
      <template #default="props">
          <nav-bar-bottom :state="props.state" :active-name="$route.meta.navBarName" v-if="!$scopedSlots.navBar" />
          <slot name="navBar" v-else></slot>
      </template>
    </sticky-bar>
<!--    <sticky-bar v-if="$route.meta.showNavBar==='pinlite'"  :direction="direction" position="bottom" :scroll-y="scroll.y" >-->
<!--      <template #default="props">-->
<!--        <nav-bar-bottom-pinlite :state="props.state" :active-name="$route.meta.navBarName" />-->
<!--      </template>-->
<!--    </sticky-bar>-->
  </div>
</template>
<script lang=ts>
    import {BigBigWork, CacheHistory, keepCache} from "bbw-common";
    import NavBarBottom from "../nav-bar-bottom/nav-bar-bottom.vue";
    import NavBarBottomPinlite from "../nav-bar-bottom-pinlite/nav-bar-bottom-pinlite.vue";
    import StickyBar from "../sticky-bar/sticky-bar.vue";
    import qs from "querystring";

    export default {
        name: `ActivityView`,
        components: { keepCache, NavBarBottom, StickyBar, NavBarBottomPinlite },
        props: {
            /** activated, deactivated */
            state: {
                type: String,
                required: false,
                default: `activated`
            },
            /** 名字 */
            name: {
                type: String,
                required: false,
                default: `default`
            },
            /** 路由名称和动画名称键值对
             *  [{path:[route.path],animate:[animate-class-name]}]
             */
            animateList: {
                type: Array,
                required: false
            },
            /** 是否模糊 */
            blur:{
                type: Boolean,
                required: false,
                default:false
            }
        },
        beforeMount ( ) {
            CacheHistory.scrollActivity = this ;
        },
        mounted () {
            console.dir ( `mounted` ) ;
            this.state === `activated` && this.entry () ;
        },
        activated ( ) {
            console.dir ( `activated` ) ;
            this.state === `activated` && this.entry () ;
        },
        deactivated() {
        },
        data () {
            return {
                /** ActivityView的DOM元素 */
                dom: null,
                direction:'bottom',
                /** 冻结 */
                freeze: false,
                /** 滚动位置 */
                scroll: {
                    x: 0,
                    y: 0,
                    pullingDown: false
                },
                touch: {
                    start: {
                        x:0,
                        y:0
                    },
                    end:{
                        x:0,
                        y:0
                    }
                }
            } ;
        },
        methods: {
            entry () {
                CacheHistory.scrollActivity = this ;
                this.dom = this.$refs.view ;
                this.$on ( `scroll`, ( e ) => {
                    this.scroll.y = e.y ;
                    this.scroll.x = e.x ;
                } ) ;
                BigBigWork.emit ( `ActivityViewMounted`, this.name ) ;
            },
            exit () {
                BigBigWork.emit ( `ActivityViewDestroyed`, this.name ) ;
            },
            handleChangeDirectionVisible(e){
              this.$emit("ChangeDirectionVisible",e)
            },
            handleTouchStart( ev:TouchEvent ){
                this.touch.start = { x: ev.changedTouches[0].pageX, y: ev.changedTouches[0].pageY}
            },
            handleTouchEnd( ev:TouchEvent ){
                this.touch.end = { x: ev.changedTouches[0].pageX, y: ev.changedTouches[0].pageY};
                const dist = this.touch.end.y - this.touch.start.y;
                const o_direction = this.direction;
                if( dist > 10 ){
                    this.direction = 'bottom';
                }
                if(dist < -10 ) {
                    this.direction = 'top';
                }
            }
        },
        watch: {
            state ( newVal, oldValue ) {
                newVal === `activated` ? this.entry () : this.exit () ;
            },
            $route ( newVal, oldValue ){
                if(newVal !== oldValue){
                    setTimeout(()=>{
                        this.direction = 'bottom';
                        console.dir(`derection: ${this.direction}`)
                    },500);
                }
            }
        },
        computed: {
            key () {
                return this.$store.state.route.path + `?` + qs.stringify ( this.$store.state.route.query );
            }
        }

  }
</script>
