import { Module } from "vuex" ;
import { ResultItem } from "bbw-common" ;

export interface StoreFavBoard {
    currentItem: ResultItem | null,
    show: Boolean,
    items: ResultItem[]
}

export const ModuleFavBoard:Module<StoreFavBoard, any> = {
    namespaced: true,
    state: {
        currentItem: null,
        show: false,
        items: []
    },
    mutations: {
        setItems ( state, data:ResultItem[] ) {
            state.items = data ;
        },
        addItems ( state, data:ResultItem[] ) {
            state.items = [...this.items, data] ;
        },
        setVisible ( state, data:Boolean ) {
            state.show = data ;
        },
        setCurrentItem ( state, data: ResultItem ) {
            state.currentItem = data ;
        }
    }
} ;
