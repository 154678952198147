import { routerMeta } from "./meta" ;
import VueRouter, { RouteConfig } from 'vue-router' ;
import Vue from 'vue' ;
import { RouterMeta } from "bbw-server" ;

Vue.use ( VueRouter ) ;

// 404
const Page404 = () => import ( /* webpackChunkName:"page404" */ `@pages/404/404.vue` ) ;

// error
const PageError = () => import ( /* webpackChunkName:"pageError" */ `@pages/error/error.ts` ) ;

// 大图页
const PagePin = () => import ( /* webpackChunkName:"pagePin" */ `@pages/pin/pin.ts` ) ;
// 大图页
const PagePinSource = () => import ( /* webpackChunkName:"pagePinSource" */ `@pages/pin/source/source.ts` ) ;
// 大图页
const PageAuth = () => import ( /* webpackChunkName:"pagePin" */ `@pages/auth/auth.vue` ) ;

// 用户页
const PageUser = () => import ( /* webpackChunkName:"pageUser" */ `@pages/mycloud/mycloud.ts` ) ;

// 用户board页
const PageBoard = () => import ( /* webpackChunkName:"pageBoard" */ `@pages/board/board.ts` ) ;
// 用户section页
const PageSection = () => import ( /* webpackChunkName:"PageSection" */ `@pages/section/section.ts` ) ;
// 退出登录
const PageLogout = () => import ( /* webpackChunkName:"pageLogout" */ `@pages/account/logout/logout.vue` ) ;
// PWA启动页
const PagePWA = () => import ( /* webpackChunkName:"pagePWA" */ `@pages/pwa/pwa.vue` ) ;
// 版本
const PageVersion = () => import ( /* webpackChunkName:"pagePWA" */ `@pages/account/version/version.vue` ) ;
// pinlite首页
const PagePinliteTupian = () => import ( /* webpackChunkName:"pagePinliteTupian" */ `@pages/tupian/tupian.ts` ) ;
// pinlite搜索
const PagePinliteSearch = () => import ( /* webpackChunkName:"pagePinliteSearch" */ `@pages/search/search.ts` ) ;
// pinlite大图页
const PagePinlitePin = () => import ( /* webpackChunkName:"pagePinlitePin" */ `@pages/image/image.ts` ) ;
// pinlite大图页
const PagePinliteIndex = () => import ( /* webpackChunkName:"pagePinliteIndex" */ `@pages/index/index.vue` ) ;
// sem页面
const PagePinliteSem = () => import ( /* webpackChunkName:"pagePinliteIndex" */ `@pages/sem/index.vue` ) ;


/** 路由配置
 * @description
 * meta部分说明：
 * keepAlive: <keepLive>组件是否缓存页面组件;
 * auth: 是否是登录用户才能访问该路由；
 * cache: 是否需要被服务器缓存;
 * enableSearchParams: 是否要在缓存名后加上searchParams参数
 * authRedirect: 访问需要登录的路由，但验证未通过时跳转的路由
 */
export const RouterConfig:RouteConfig[] = [
    {
        path: `/account/logout`,
        component: PageLogout,
        name: `logout`,
        meta: { showNavBar: '', navBarName: `` }
    },
    {
        path: `/account/pwa`,
        component: PagePWA,
        name: `pwa`,
        meta: { showNavBar: '' }
    },
    {
        path: `/auth`,
        component: PageAuth,
        name: `auth`,
        meta: { showNavBar: '' }
    },
    {
        path: `/account/version`,
        component: PageVersion,
        name: `version`,
        meta: { showNavBar: '' }
    },
    {
        path: `/pin/source.html`,
        component: PagePinSource,
        name: `pinSource`,
        meta: { showNavBar: '', navBarName: `` }
    },
    {
        path: `/pin/:imgId.html`,
        component: PagePin,
        name: `pin`,
        meta: { showNavBar: 'pinlite', navBarName: `` }
    },
    {
        path: `/board/:boardId`,
        component: PageBoard,
        name: `board`,
        meta: { showNavBar: 'pinlite', navBarName: `` }
    },
    {
        path: `/board/:boardId/:uuid`,
        component: PageSection,
        name: `section`,
        meta: { showNavBar: `www`, navBarName: `user`, showRightSidePay: true }
    },
    {
        path: `/404.html`,
        component: Page404,
        name: `404`,
        meta: { showNavBar: '' }
    },
    {
        path: `/error.html`,
        component: PageError,
        name: `error`,
        meta: { showNavBar: '' }
    },
    {
        path: `/mycloud`,
        component: PageUser,
        name: `pinliteMycloud`,
        meta: { showNavBar: 'pinlite', navBarName: `user` }
    },
    {
        path: `/`,
        name: `pinliteHome`,
        alias: '/home',
        component: PagePinliteTupian,
        meta: { showNavBar: 'pinlite', navBarName: `pinlite` }
    },
    {
        path: `/index`,
        alias: `/zh/pinlite.html`,
        name: `pinliteIndex`,
        component: PagePinliteIndex,
        meta: { showNavBar: '', navBarName: `` }
    },
    {
        path: `/tupian`,
        name: `pinliteTupian`,
        component: PagePinliteTupian,
        meta: { showNavBar: 'pinlite', navBarName: `pinlite` }
    },
    {
        path: `/search`,
        name: `pinliteSearch`,
        component: PagePinliteSearch,
        meta: { showNavBar: '', navBarName: `pinlite` }
    },
    {
        path: `/tupian/image/:imgId.html`,
        name: `pinlitePin`,
        component: PagePinlitePin,
        meta: { showNavBar: 'pinlite', navBarName: `pinlite` }
    },
    {
        path: `/sem`,
        name: `pinliteSem`,
        component: PagePinliteSem,
        meta: { showNavBar: '', navBarName: `` }
    },
    {
        path: `*`,
        component: Page404,
        meta: { showNavBar: false }
    }
] ;
/** 合并RouterConfig和Meta配置 */
export const CreateRouterConfig = <T extends string>( routerConfig:RouteConfig[], routerMeta:RouterMeta< T, any> ) => {
    try {
        for ( let i = 0 ; i < routerConfig.length ; i++ ) {
            const configItem = routerConfig[i] ;
            const meta = routerMeta[configItem.path] || {} ;
            configItem.meta = Object.assign ( configItem.meta || {}, meta ) ;
        }
        return routerConfig ;
    } catch ( e ) {
        throw e ;
    }
} ;

export default function CreateRouter () {
    const Router = new VueRouter ( {
        mode: `history`,
        fallback: false,
        scrollBehavior: ( to, from, savedPosition ) => {
            if ( savedPosition && to.meta.keepAlive ) {
                return savedPosition ;
            }
            return { x: 0, y: 0 } ;
        },
        routes: CreateRouterConfig ( RouterConfig, routerMeta )
    } ) ;
    return Router ;
}
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
