import { RouterMataOptions, RouterMeta, OptionsFunction } from "bbw-server" ;
import * as Bowser from "bowser" ;
import { Request } from "express" ;
const __DEV__ = process.env.NODE_ENV === `development` ;
const ServerMobileServer = __DEV__ ? `//m-test.bigbigwork.com` : `//m.bigbigwork.com` ;

/** 未登录跳转 */
const authRedirect = ( req:Request ) => {
    const pinliteDomain = process.env.PINLITE_DOMAIN||'pinlite.net';
    return `https:${ServerMobileServer}/auth/thirdlogin?target=pinlite&domain=${pinliteDomain}&redirect=${req.url}` ;
} ;

/** 首页未登录跳转 */
const homeAuthRedirect = ( req:Request ) => {
    return `/index` ;
} ;

/** RouterMeta
 * @description
 * meta部分说明：
 * keepAlive: <keepLive>组件是否缓存页面组件;
 * auth: 是否是登录用户才能访问该路由；
 * cache: 是否需要被服务器缓存;
 * enableSearchParams: 是否要在缓存名后加上searchParams参数
 * authRedirect: 访问需要登录的路由，但验证未通过时跳转的路由
 */
export const routerMeta:RouterMeta<string, RouterMataOptions> = {
    "/account/logout": {
        keepAlive: true,
        auth: false,
        cache: `/account/logout`,
        enableSearchParams: false
    },
    "/account/pwa": {
        keepAlive: true,
        auth: false,
        cache: `/account/pwa`,
        enableSearchParams: false
    },
    "/account/version": {
        keepAlive: true,
        auth: false,
        cache: `/account/version`,
        enableSearchParams: false
    },
    "/auth": {
        keepAlive: true,
        auth: false,
        cache: `/auth`,
        enableSearchParams: false
    },
    "/": {
        keepAlive: true,
        auth: true,
        cache: `/`,
        enableSearchParams: false,
        authRedirect: homeAuthRedirect
    },
    "/index": {
        keepAlive: true,
        auth: false,
        cache: `/index`,
        enableSearchParams: false,
        header: [{ name: `Cache-Control`, value: `public,max-age= 0` }]
    },
    "/sem": {
        keepAlive: true,
        auth: false,
        cache: `/sem`,
        enableSearchParams: false
    },
    "/tupian": {
        keepAlive: true,
        auth: true,
        cache: `/tupian`,
        enableSearchParams: false,
        authRedirect: authRedirect
    },
    "/home": {
        keepAlive: true,
        auth: true,
        cache: `/home`,
        enableSearchParams: false,
        authRedirect: homeAuthRedirect
    },
    "/search": {
        keepAlive: true,
        auth: true,
        cache: `/search`,
        enableSearchParams: false,
        authRedirect: authRedirect
    },
    "/pwa": {
        keepAlive: true,
        auth: false,
        cache: `/pwa`,
        enableSearchParams: false
    },
    "/pin/source.html": {
        keepAlive: true,
        auth: false,
        cache: `/pin/source.html`,
        enableSearchParams: false
    },
    "/pin/:imgId.html": {
        keepAlive: true,
        auth: true,
        cache: `/pin/:imgId.html`,
        enableSearchParams: false,
        authRedirect: authRedirect
    },
    "/mycloud": {
        keepAlive: true,
        auth: true,
        cache: `/mycloud`,
        enableSearchParams: false,
        authRedirect: authRedirect
    },
    "/board/:boardId": {
        keepAlive: true,
        auth: true,
        cache: `/board/:boardId`,
        enableSearchParams: false,
        authRedirect: authRedirect
    },
    "/tupian/image/:imgId.html": {
        keepAlive: true,
        auth: false,
        cache: `/tupian/image/:imgId.html`,
        enableSearchParams: false
    },
    "/error": {
        keepAlive: true,
        auth: false,
        cache: `/error`,
        enableSearchParams: false
    },
    "/404": {
        keepAlive: true,
        auth: false,
        cache: `/404`,
        enableSearchParams: false
    }
} ;
