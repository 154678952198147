var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.$style.bar, _vm.$style.pinlite].concat(_vm.navStyle),
      on: {
        "!touchstart": function ($event) {
          $event.stopPropagation()
        },
        "!touchend": function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("div", { class: _vm.$style.navItem }, [
        _c(
          "a",
          {
            class: [_vm.$style.link],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClickNav("bbw")
              },
            },
          },
          [
            _c(
              "svg",
              {
                class: _vm.$style.icon,
                attrs: {
                  width: "36",
                  height: "36",
                  viewBox: "0 0 36 36",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M18 36C8.059 36 0 27.941 0 18S8.059 0 18 0s18 8.059 18 18-8.059 18-18 18zm-.234-19.732V29.98h6.476v-3.292h3.264l.603-.035c1.129-.176 2.158-.62 3.752-1.835l-2.139-3.83c-.94.665-1.75 1.036-2.947 1.22h-2.533V19.98h2.739l.677-.045c.9-.113 2.084-.61 3.678-1.825l-2.139-3.83c-.94.665-1.75 1.036-2.947 1.22h-2.008v-2.236h1.313l.828-.121c1.038-.13 2.254-.67 4.305-2.37l-2.126-3.808c-1.327 1.052-2.222 1.586-3.737 1.819h-3.063c.163-1.212.156-2.198.124-2.67h-5.91c.113.874.246 4.013-.734 5.259-.268.342-.384.805-1.653.88v5.36c1.803-.12 3.164-.668 4.177-1.345zm-11.283.9V30h6.475V14.11H11.08c2.091-3.036 2.207-6.998 2.14-8.11H7.576c.056 1.04-.129 4.068-1.065 5.259-.269.341-.773.804-2.042.881L4.4 17.504c.75-.045 1.445-.164 2.083-.336z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("大作首页")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.navItem }, [
        _c(
          "a",
          {
            class: [_vm.$style.link],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClickNav("search")
              },
            },
          },
          [
            _c(
              "svg",
              {
                class: _vm.$style.icon,
                attrs: {
                  width: "40",
                  height: "40",
                  viewBox: "0 0 40 40",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M28.588 25.157H26.78l-.64-.617a14.8 14.8 0 003.59-9.674C29.731 6.656 23.076 0 14.866 0S0 6.655 0 14.866c0 8.21 6.655 14.865 14.866 14.865a14.8 14.8 0 009.674-3.59l.617.64v1.807L36.592 40 40 36.592 28.588 25.157zm-13.722 0A10.278 10.278 0 014.574 14.866 10.278 10.278 0 0114.866 4.574a10.278 10.278 0 0110.291 10.292 10.278 10.278 0 01-10.291 10.291z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("灵感分类")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.navItem }, [
        _c(
          "a",
          {
            class: [
              _vm.$style.link,
              _vm.isActive("pinlite") ? _vm.$style.linkActive : "",
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClickNav("pinlite")
              },
            },
          },
          [
            _c(
              "svg",
              {
                class: _vm.$style.icon,
                attrs: {
                  width: "36",
                  height: "36",
                  viewBox: "0 0 36 36",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M22.22 35.503v.38h-2.164a17.937 17.937 0 002.164-.38zm2.567-.826v1.207h-1.534v-.663c.522-.159 1.034-.34 1.534-.544zm2.549-1.284v1.072c0 .12.113.199.34.24h.373v1.179h-1.27c-.511-.117-.815-.422-.912-.917v-.772c.503-.245.993-.513 1.469-.802zm.793-.511c.857-.585 1.66-1.24 2.402-1.96 1.57.013 2.392.981 2.463 2.904h-3.441c.115.718.465 1.076 1.05 1.076.398 0 .67-.181.818-.545h1.507C32.581 35.452 31.806 36 30.603 36c-1.529 0-2.53-1.143-2.551-2.174-.006-.31.016-.633.077-.944zm1.464.038h1.86c-.11-.634-.42-.951-.93-.951-.511 0-.821.317-.93.951zM14.714 35.7c.351-.077.695-.164 1.031-.26.255-2.924.979-6.811 2.17-11.664 1.078.454 2.368.681 3.872.681 2.382 0 4.424-.553 6.126-1.66 3.148-2.015 4.723-4.994 4.723-8.939 0-3.008-1.107-5.221-3.319-6.64-2.212-1.448-5.474-2.171-9.785-2.171-4.312 0-8.084.88-11.317 2.639-3.234 1.76-4.85 4.086-4.85 6.98 0 1.618.496 2.924 1.489 3.917.964.993 1.985 1.56 3.063 1.703.595-1.079.95-2.129 1.063-3.15-.652-.17-1.29-.497-1.914-.98-.596-.51-.894-1.163-.894-1.957 0-1.561 1.163-3.051 3.49-4.47 2.325-1.419 5.246-2.128 8.763-2.128 2.695 0 4.638.51 5.83 1.532 1.219.993 1.829 2.61 1.829 4.853 0 1.22-.142 2.383-.426 3.49-.255 1.079-.822 2.086-1.702 3.022-.879.937-2 1.405-3.36 1.405-.795 0-1.504-.128-2.128-.383 1.361-5.505 2.042-8.698 2.042-9.577 0-.909-.07-1.505-.213-1.788-.425-.142-1.077-.213-1.957-.213-2.24 0-4.112.44-5.616 1.32l-.042.297c.425.483.695.852.808 1.107.142.256.213.653.213 1.192 0 1.703-.738 5.378-2.212 11.025-1 3.905-1.69 6.888-2.066 8.947C3.811 30.783 0 24.837 0 18 0 8.059 8.059 0 18 0s18 8.059 18 18c0 5.07-2.096 9.65-5.469 12.922h-.034c-1.513 0-2.162.921-2.368 1.96-.26.177-.524.347-.793.51v-1.377h.713v-.933h-.713V29.52h-1.47v1.562h-.512v.933h.513v2.18c-.354.172-.714.332-1.08.482v-3.595h-1.534v4.14c-.34.103-.685.197-1.033.28V34.49h-2.665v1.394h.501a18.193 18.193 0 01-5.342-.183zm3.266-6.533v6.717h1.575v-6.717H17.98zm6.064 1.545a.952.952 0 100-1.904.952.952 0 000 1.904z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("Pin极速版")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.navItem }, [
        _c(
          "a",
          {
            class: [
              _vm.$style.link,
              _vm.isActive("user") ? _vm.$style.linkActive : "",
            ],
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClickNav("user")
              },
            },
          },
          [
            _c("div", { class: _vm.$style.avatar }, [
              _c("img", { attrs: { src: _vm.userAvatar } }),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("我的")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }