<template>
  <div id="app">
    <activity-view @scrollY="handleScrollY" :location="$store.state.route"/>
    <!--    <sticky-bar v-if="$route.meta.showNavBar" :scroll-y="scrollY">-->
    <!--      <template v-slot:default="props">-->
    <!--        <nav-bar-bottom :state="props.state" :active-name="$route.meta.navBarName"></nav-bar-bottom>-->
    <!--      </template>-->
    <!--    </sticky-bar>-->
    <!-- 收藏弹框 -->
    <transition name="van-slide-right">
      <component :is="favBoard" v-if="showFavBoard"
                 :item="$store.state.FavBoard.currentItem"></component>
    </transition>
    <!-- 设置弹框 -->
    <transition name="van-slide-left">
      <component :is="settings" v-if="showSettings"></component>
    </transition>
    
    <!-- 原图弹框 -->
    <transition name="van-fade">
      <component :is="originImage" v-if="showOriginImage"></component>
    </transition>
  </div>
</template>
<script lang=ts>
    import { NavBarBottom, StickyBar, ActivityView } from "bbw-m-components/components";
    import {BigBigWork, keepCache, parseHash} from "bbw-common";
    import Router, {Route} from "vue-router"

    export default {
        name: "Entry",
        components: {keepCache, NavBarBottom, StickyBar, ActivityView},
        beforeMount() {
        },
        async mounted() {
            this.$store.commit('updateBrowserEnv')
        },
        data() {
            return {
                /** 收藏组件 */
                favBoard: null,
                /** 显示收藏组件 */
                showFavBoard: false,
                /** 设置组件 */
                settings:null,
                /** 显示收藏组件 */
                showSettings: false,
                /** 原图组件 */
                originImage:null,
                /** 显示原图组件 */
                showOriginImage:false
            };
        },
        methods: {
            handleScrollY(scrollY) {
                this.scrollY = scrollY;
            }
        },
        watch: {
            async $route( newVal:Route, oldVal:Route ){
                const hashObj = parseHash( newVal.hash );
                if(hashObj['fav'] && this.$store.state.FavBoard.currentItem){
                    if(!this.favBoard){
                        // await import( /* webpackChunkName:"FavBoardCss" */ "bbw-m-components/lib/assets/fav-board.css" );
                        const FavBoard = await import( /* webpackChunkName:"FavBoard" */ "bbw-m-components/src/components/fav-board/" );
                        this.favBoard = FavBoard.default;
                    }
                    this.showFavBoard = true;
                }else{
                    this.showFavBoard = false;
                }
                /** 设置弹窗 */
                if(hashObj['settings']){
                    if(!this.settings){
                        // await import( /* webpackChunkName:"FavBoardCss" */ "bbw-m-components/lib/assets/dialog-settings.css" );
                        const settings = await import( /* webpackChunkName:"Settings" */ "bbw-m-components/src/components/dialog-settings/" );
                        this.settings = settings.default;
                    }
                    this.showSettings = true;
                }else{
                    this.showSettings = false;
                }
                /** 微信原图弹窗 */
                if(hashObj['download']){
                    if(!this.originImage){
                        // await import( /* webpackChunkName:"FavBoardCss" */ "bbw-m-components/lib/assets/dialog-big-image.css" );
                        const origin = await import( /* webpackChunkName:"Download" */ "bbw-m-components/src/components/dialog-big-image/" );
                        this.originImage = origin.default;
                    }
                    this.showOriginImage = true;
                }else{
                    this.showOriginImage = false;
                }
                if(newVal.hash === 'weixin' && !this.$store.state.isWechatBrowser ){
                
                }
            }
        }
    }
</script>
