import { BigBigWork, GuideOptions, checkRedEnvelopeCookie } from "bbw-common" ;
import Vue from "vue" ;
export const GuideConfig:GuideOptions[] = [
    // {
    //     name: `大红包`,
    //     order: 3,
    //     conditionEvent: `_START_GUIDE`,
    //     once: true,
    //     onAction: async ( guide, store ) => {
    //         try {
    //             console.log ( `大红包` ) ;
    //             const redEnvelopeCookie = await checkRedEnvelopeCookie ().catch ( e => { throw e ; } )  ;
    //             if ( !redEnvelopeCookie ) {
    //                 // 本地无cookie 弹框
    //                 const DialogRedEnvelopeVue = await import ( `../../../../node_modules/bbw-components/modules/browser/packages/bbw-components/src/components/DialogRedEnvelope` ).catch ( e => { throw e ; } ) ;
    //                 // @ts-ignore
    //                 await import ( `../../../../node_modules/bbw-components/lib/assets/cDialogRedEnvelope.css` ).catch ( e => { throw e ; } ) ;
    //                 const DialogRedEnvelope = new Vue ( { ...DialogRedEnvelopeVue.default } ) ;
    //                 DialogRedEnvelope.$store = store ;
    //                 const el = document.createElement ( `div` ) ;
    //                 document.body.appendChild ( el ) ;
    //                 DialogRedEnvelope.$mount ( el ) ;
    //             } else {
    //                 BigBigWork.emit ( `_GUIDE_NextGuide`, `_START_GUIDE` ) ;// 下一个向导
    //             }
    //         } catch ( e ) {
    //             BigBigWork.emit ( `_GUIDE_NextGuide`, `_START_GUIDE` ) ;// 下一个向导
    //             throw e ;
    //         }
    //     }
    // }
] ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
