import Vue from 'vue' ;
import Vuex from 'vuex' ;
import { ModuleFavBoard } from "./favBoard" ;
import { StoreSearch } from './search' ;
import { getDomain, ServerWww, StoreData, StorePinliteUser, StoreUserInfo } from "bbw-common" ;
import Browser from "bowser" ;
import Cookies from "js-cookie" ;
Vue.use ( Vuex ) ;

export type State = {
    title: string,
    description:string,
    keywords:string,
    server: boolean,
    clientWidth: number,
    debug: unknown,
    token: string,
    cache: {},
    bottomNavState: `fixed` | `fixedHidden`,
    bottomNavFreeze:boolean,
    showPwaInstall: boolean,
    isWechatBrowser: boolean,
    isIOS:boolean,
    memberNumber: number,
    isPWA: boolean,
    originImageSrc:string,
    isPinlite: boolean,
    isAPK: boolean,
    isFocusInput: boolean
}

export default function CreateStore () {
    return new Vuex.Store<State> ( {
        state: {
            title: `Pinterest极速版`,
            keywords: `Pinterest,Pinlite,pinterest官网,pinterest网站,pinterest中文版,pinterest设计网站,pinterest怎么打不开了`,
            description: `Pinlite-Pinterest中文极速网页版，不用下载APP插件，您可以方便快捷的登录注册Pinterest网站,收藏下载来自全球各地设计师分享的创意设计图片，再也不会出现pinterest怎么打不开了的困惑。`,
            /** 是否处于服务端 */
            server: true,
            /** 屏幕宽度 */
            clientWidth: 1200,
            /** debug信息存放处 */
            debug: {},
            /** 用户请求带来的token */
            token: ``,
            /** 用于服务器和客户端共享的缓存 */
            cache: {},
            /** 底部导航状态 */
            bottomNavState: `fixed`,
            /** 底部导航冻结(冻结期间滚动无效) */
            bottomNavFreeze: false,
            /** 是否显示安装PWA按钮 */
            showPwaInstall: true,
            /** 是否微信浏览器 */
            isWechatBrowser: false,
            /** 用户数量 */
            memberNumber: 600,
            /** 是否是PWA */
            isPWA: false,
            /** 是否是IOS */
            isIOS: false,
            /** 原图URL */
            originImageSrc: ``,
            /** 是否是Pinlite */
            isPinlite: false,
            /** APK */
            isAPK: false,
            /** 是否聚焦输入框 */
            isFocusInput: false
        },
        mutations: {
            updateBrowserEnv: function ( state ) {
                const browser = Browser.parse ( window.navigator.userAgent ) ;
                const env = browser.browser.name.toLowerCase () === `wechat` ;
                console.log ( `isWeixin`, env ) ;
                state.isWechatBrowser = env ;
            },
            updateOSEnv: function ( state ) {
                const browser = Browser.parse ( window.navigator.userAgent ) ;
                const env = browser.os.name.toLowerCase () === `ios` ;
                state.isIOS = env ;
            },
            setTitle: function ( state, val ) {
                state.title = val ;
            },
            setKeywords: function ( state, val ) {
                state.keywords = val ;
            },
            setDescription: function ( state, val ) {
                state.description = val ;
            },
            setDebug: function ( state, data: { key:string, value:any } ) {
                const { key, value } = data ;
                state.debug[key] = value ;
            },
            setIsClient: function ( state ) {
                state.server = false ;
            },
            setClientWidth: function ( state, val ) {
                state.clientWidth = val ;
            },
            setToken: function ( state, val ) {
                state.token = val ;
            },
            setCache: function ( state, data ) {
                const { key, value } = data ;
                state.cache[key] = value ;
            },
            setBottomNavState: function ( state, data ) {
                state.bottomNavState = data ;
            },
            setBottomNavFreeze: function ( state, data ) {
                state.bottomNavFreeze = data ;
            },
            setShowPWAInstall: function ( state, data ) {
                state.showPwaInstall = data ;
                if ( data ) {
                    Cookies.set ( `showPWAInstall`, data, { domain: getDomain (), expires: 1 } ) ;
                } else {
                    Cookies.remove ( `showPWAInstall`, { domain: getDomain () } ) ;
                }
            },
            setMember: function ( state, data ) {
                state.memberNumber = Math.ceil ( data / 10000 ) ;
            },
            setPWA: function ( state, data ) {
                state.isPWA = data ;
                state.showPwaInstall = !data ;
            },
            setAPK: function ( state, data ) {
                if ( data ) {
                    state.isPWA = data ;
                    state.showPwaInstall = !data ;
                }
                state.isAPK = data ;
            },
            setOriginImageSrc: function ( state, data ) {
                state.originImageSrc = data ;
            },
            setIsFocusInput: function ( state, data ) {
                state.isFocusInput = data;
            }
        },
        actions: {
            FETCH_INDEX_TITLE: ( context ) => {
                return Promise.resolve ( true ).then ( () => {
                    context.commit ( `setTitle`, `PinLite` ) ;
                    return true ;
                } ) ;
            }
        },
        getters: {
            token: ( state ) => {
                return state.token ;
            },
            statSource: ( state ) => {
                let source = `M-Pinlite` ;
                state.isPWA && ( source = `PWA` ) ;
                state.isAPK && ( source = `APK` ) ;
                return source ;
            }
        },
        modules: {
            UserInfo: StoreUserInfo.UserInfo,
            FavBoard: ModuleFavBoard,
            Search: StoreSearch,
            Data: StoreData.Data,
            PinUser: StorePinliteUser.PinUser
        }
    } ) ;
}
