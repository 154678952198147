/* eslint-disable camelcase */
import {
    AnalysisSendAnalysis,
    AnalysisSimilarImgButton,
    BigBigWork
} from "bbw-common" ;

/** 百度统计ID */
export const AnalysisPinliteBaiduId = `a073fb3d93f1ef97bcbdd462f98e2d8d` ;
export const AnalysisBigNetBaiduId = `6adb9c7b9fb616ffdc48ff2efbbb8233`
/** 初始化统计 */
export const AnalysisInit = ( ) => {
    initMassageMap ( ) ;
} ;

/** 初始化消息循环 */
const initMassageMap = ( ) => {
    console.log ( `AnalysisStart` ) ;
    BigBigWork.addListener ( `Analysis`, ( msg:{ type:string, data:any } ) => {
        console.log ( JSON.stringify(msg) ) ;
        const { type, data } = msg ;
        if ( !type ) {
            __DEV__ && console.log ( `发送分析错误` ) ;
        } else {
            messageMap ( type, data ) ;
        }
    } ) ;
} ;

/** 统计消息循环 */
const messageMap = ( type:string, data ) => {
    // @ts-ignore
    if ( !window._hmt ) return ;
    switch ( type ) {
    case `openPay`:
        AnalysisSendAnalysis ( type, data ) ;
        break ;
        /** 收藏统计 */
    case `AnalysisCollectImage`:
        AnalysisSendAnalysis ( `CollectImage`, data ) ;
        break ;
        /** 下载统计 */
    case `AnalysisDownloadImage`:
        AnalysisSendAnalysis ( `DownloadImage`, data ) ;
        break ;
        /** 搜索统计 */
    case `AnalysisSearch`:
        AnalysisSendAnalysis ( `Search`, data ) ;
        break ;
        /** 相似图统计 */
    case `similarImgButton`:
        AnalysisSimilarImgButton ( data ) ;
        break ;
        /** 聚合图片显示的统计 */
    case `JhpinsImpression`:
        AnalysisSendAnalysis (`JhpinsImpression`, data )
        break ;
        /** 聚合图片点击时的统计 */
    case `JhpinsHits`:
        AnalysisSendAnalysis (`JhpinsHits`, data )
        break ;
    default:
        console.warn ( `消息类型${type}未处理` ) ;
    }
} ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
