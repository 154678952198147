import Vuex, { Module } from "vuex" ;
import axios from "axios" ;
import { NetError, ServerWww, LocalStorageRead, LocalStorageWrite } from "bbw-common" ;

interface StateSearch {
    historyWords:Array<string>,
    hotWords:Array<string>
}

export const StoreSearch:Module<StateSearch, any> = {
    namespaced: true,
    state: {
        /** 搜索历史 */
        historyWords: [],
        /** 热词 */
        hotWords: []
    },
    mutations: {
        addHistoryWord ( state, word ) {
            // 去重
            let index:number = null ;
            for ( let i = 0 ; i < state.historyWords.length ; i++ ) {
                const val = state.historyWords[i] ;
                if ( word === val ) {
                    index = i ;
                    break ;
                }
            }
            if ( index !== null ) {
                state.historyWords.splice ( index, 1 ) ;
            }
            if ( state.historyWords.length > 4 ) {
                state.historyWords.pop () ;
            }
            state.historyWords.unshift ( word ) ;
        },
        setHistoryWords ( state, data ) {
            state.historyWords = data ;
        },
        setHotWords ( state, data ) {
            state.hotWords = data ;
        }
    },
    actions: {
        /** 写入单个历史搜索词 */
        WRITE_HISTORY_WORD: ( { commit, rootState, state }, word ) => {
            try {
                commit ( `addHistoryWord`, word ) ;
                const id = rootState.UserInfo.id ;
                LocalStorageWrite ( `history`, id, state.historyWords, null, false, 5 ) ;
                return Promise.resolve ( true ) ;
            } catch ( e ) {
                throw e ;
            }
        },
        /** 写入历史搜索词 */
        WRITE_HISTORY: ( { commit, rootState, state } ) => {
            try {
                const id = rootState.UserInfo.id ;
                LocalStorageWrite ( `history`, id, state.historyWords, null, false, 5 ) ;
                return Promise.resolve ( true ) ;
            } catch ( e ) {
                throw e ;
            }
        },
        /** 读取历史搜索词 */
        GET_HISTORY_WORDS: ( context ):Promise<string[]> => {
            const id = context.rootState.UserInfo.id ;
            const result = LocalStorageRead<string[]> ( `history`, id, false ) || [] ;
            context.commit ( `setHistoryWords`, result ) ;
            return Promise.resolve ( result ) ;
        },
        /** 从服务端获取热词 */
        FETCH_HOT_WORDS: async ( context, params = {} ):Promise<string[]> => {
            const { num = 6, isForce = false } = params ;
            // 如果非强制刷新，如果已有热词则直接返回
            if ( !isForce && context.state.hotWords.length ) {
                return ;
            }
            return axios ( {
                url: ServerWww + `/getHotWords`,
                params: {
                    num
                }
            } ).then ( res => {
                if ( res.status !== 200 || res.data.code !== 200 ) {
                    throw new NetError ( JSON.stringify ( res ) ) ;
                }
                const hotWords = res.data.hotWordList.map ( val => {
                    return val.word ;
                } ) ;
                context.commit ( `setHotWords`, hotWords ) ;
                return hotWords ;
            } ).catch ( e => {
                throw e ;
            } ) ;
        }
    }
} ;
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
