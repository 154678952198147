import axios from "axios" ;
/** 获取微信js-sdk授权 */
export const fetchWXConfig = async () => {
    axios ( {
        url: `//mp.bigbigwork.com/authentication/jsapiSignature`
    } ).then ( res => {
        console.dir ( res ) ;
    } ).catch ( e => {
        throw e ;
    } ) ;
} ;
