import { render, staticRenderFns } from "./activity-view.vue?vue&type=template&id=10ad64ce&"
import script from "./activity-view.vue?vue&type=script&lang=ts&"
export * from "./activity-view.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/front-end/rabbit-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10ad64ce')) {
      api.createRecord('10ad64ce', component.options)
    } else {
      api.reload('10ad64ce', component.options)
    }
    module.hot.accept("./activity-view.vue?vue&type=template&id=10ad64ce&", function () {
      api.rerender('10ad64ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "bbw-m-components/src/components/activity-view/activity-view.vue"
export default component.exports