var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("activity-view", {
        attrs: { location: _vm.$store.state.route },
        on: { scrollY: _vm.handleScrollY },
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "van-slide-right" } },
        [
          _vm.showFavBoard
            ? _c(_vm.favBoard, {
                tag: "component",
                attrs: { item: _vm.$store.state.FavBoard.currentItem },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "van-slide-left" } },
        [_vm.showSettings ? _c(_vm.settings, { tag: "component" }) : _vm._e()],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "van-fade" } },
        [
          _vm.showOriginImage
            ? _c(_vm.originImage, { tag: "component" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }