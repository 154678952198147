var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "wrapper",
      class: [
        _vm.$style.sticky,
        _vm.$style[_vm.position],
        _vm.$style[_vm.showState],
      ],
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
        },
        touchend: function ($event) {
          $event.stopPropagation()
        },
        touchmove: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm._t("default", null, {
        state: _vm.showState,
        scrollY: _vm.scrollY,
        visible: _vm.scrollVisible,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }