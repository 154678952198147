var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "view",
      class: ["view", _vm.blur ? "blurPage" : ""],
      attrs: { id: _vm.state === "activated" ? "view" : "" },
      on: { touchstart: _vm.handleTouchStart, touchend: _vm.handleTouchEnd },
    },
    [
      _c(
        "transition",
        [
          _c(
            "keep-cache",
            [
              _c("router-view", {
                key: _vm.$store.state.route.path + "?" + _vm.key,
                ref: "activity",
                attrs: {
                  name: _vm.state === "activated" ? "default" : "deactivated",
                  "scroll-dom": _vm.dom,
                  "scroll-y": _vm.freeze ? 0 : _vm.scroll.y,
                  "scroll-x": _vm.scroll.x,
                  direction: _vm.direction,
                  freeze: _vm.freeze,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.getters["UserInfo/userReady"] &&
      _vm.$route.meta.showNavBar &&
      !_vm.$store.state.isFocusInput
        ? _c("sticky-bar", {
            attrs: {
              direction: _vm.direction,
              position: "bottom",
              "scroll-y": _vm.scroll.y,
            },
            on: { ChangeDirectionVisible: _vm.handleChangeDirectionVisible },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (props) {
                    return [
                      !_vm.$scopedSlots.navBar
                        ? _c("nav-bar-bottom", {
                            attrs: {
                              state: props.state,
                              "active-name": _vm.$route.meta.navBarName,
                            },
                          })
                        : _vm._t("navBar"),
                    ]
                  },
                },
              ],
              null,
              false,
              36922025
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }