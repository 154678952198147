import {
    BigBigWork,
    CacheHistory,
    downloadImg,
    EncryptionEncryptParam, getDomain,
    getDownloadImageUrl, getDownloadState, isPinlite, isSearchMobile, locationPush, PageMobilePay, PinliteItem,
    ResultItem, routerPush,
    StoreDownload
} from "bbw-common" ;
import Vue from 'vue' ;
import Router, {Route} from "vue-router" ;
import { Store } from "vuex" ;
import * as Bowser from "bowser" ;
import { Toast } from "vant" ;
import { ToastOptions } from "vant/types/toast" ;
import LRU from "lru-cache" ;
import {Scripts as bbwComponents} from "bbw-components";

export const goBack = async ( router:Router ) => {
    // 若无上一页（例如：通过分享链接，登录进来的用户），则点击返回到首页
    const currentIndex = CacheHistory.currentState.index ;
    const lastState = CacheHistory.list[currentIndex - 1] ;
    if ( currentIndex === 0 || lastState.isOld || lastState.location.indexOf ( `/account` ) > -1 ) {
        await routerPush ( router, { path: `/tupian` } ) ;
    } else {
        router.go ( -1 ) ;
    }
} ;

/** 大图页图片集来源缓存 */
export const pinCache = new LRU<string, any> ( {
    max: 500,
    maxAge: 1000 * 60 * 60
} ) ;

/** 大图页图片实例缓存 */
export const pinVMCache = new LRU<string, any> ( {
    max: 20,
    maxAge: 1000 * 60 * 60
} ) ;

/** 大图页相似图缓存 */
export const similarCache = new LRU<string, any> ( {
    max: 50,
    maxAge: 1000 * 60 * 60
} ) ;
/** 打开VIP页面 */
export const goVip = ( router:Router, fromText: string, fromType: number ) => {
    // BigBigWork.emit ( `Analysis`, { type: `openPay`, data: { from_text: `相似图`, from_type: 11 } } ) ;
    const text = EncryptionEncryptParam ( fromText ) ;
    if ( isSearchMobile () ) {
        routerPush ( router, { path: `/pay/vip.html`, query: { from_text: encodeURIComponent ( text ), from_type: fromType.toString ( 10 ) } } ) ;
    } else {
        const url = `${PageMobilePay}?from_text=${encodeURIComponent ( text )}&from_type=${fromType.toString ( 10 )}` ;
        locationPush ( url, `blank` ) ;
    }
} ;

/** 寻找Activity */
export const findActivity = ( node ) => {
    const find = ( vnode ) => {
        if ( vnode.$options._componentTag === `activity-view` ) {
            return vnode ;
        } else {
            if ( vnode.$parent ) {
                return find ( vnode.$parent ) ;
            } else {
                return null ;
            }
        }
    } ;
    return find ( node.$parent ) ;
} ;

/** 设置瀑布流显示尺寸(最高500，最小100)
 *
 * @param item
 * @param bottom 底部留白距离（使用设计稿数值即可）
 */
export const resizeItem = ( item:ResultItem, bottom:number = 0 ):ResultItem => {
    item.dw = 100 / 750 * 332 ;
    const h = item.dw / item.w * item.h ;
    // item.dh = h + 24 ;
    // 8 底部间距
    item.dh = h + ( 100 / 750 * bottom ) ;
    item.dh = Math.max ( Math.min ( 100 / 750 * 800, item.dh ), 100 / 750 * 100 ) ;
    return item ;
} ;

/** 设置Pinlite瀑布流显示尺寸(vw) **/
export const resizePiniteItem = ( item:ResultItem ):PinliteItem => {
    const dw = 100 / 750 * 332 ;
    const dh = Math.max ( Math.min ( 100 / 750 * 800, dw / item.w * item.h ), 100 / 750 * 100 ) ;
    item.dw = dw ;
    item.dh = dh + 100 / 750 * ( 26 * 2 + 8 ) ;
    return ( item as unknown as PinliteItem ) ;
} ;
/** 原图缓存 */
export const cacheDownload = new LRU ( {
    max: 10,
    maxAge: 1000 * 60 * 60
} ) ;
/** 在组件内寻找页面实例 */
const findPageInstance = ( vm ) => {
    const find = ( vm ) => {
        if ( vm.loadHandle ) {
            return vm ;
        }
        if ( vm === vm.$parent ) {
            return null ;
        }
        return find ( vm.$parent ) ;
    } ;
    return find ( vm ) ;
} ;

export type toPinTarget = `www` | `pinlite` ;

/** 从列表页跳转到大图页 */
export const toPin = async ( router:Router, pinId:number|string, index:number, itemVue, targetType:toPinTarget = `www` ) => {
    const refKey = `${window.history.state.key}` ;
    if ( targetType === 'pinlite' ) {
        await routerPush ( router, { path: `${pinliteRoot ()}/tupian/image/${pinId}.html` } ) ;
    } else {
        await routerPush ( router, { path: `/tupian/image/${pinId}.html` } ) ;
    }
    const data = {
        refKey: refKey,
        index: index
    } ;
    pinCache.set ( window.history.state.key, data ) ;
    const vm = findPageInstance ( itemVue ) ;
    pinVMCache.set ( window.history.state.key, Object.assign ( vm ) ) ;
} ;

/**
 * 下载图片流程
 * @param store vuex状态
 * @param router vue路由
 * @param styles 组件css module 样式
 * @param fromText 付费来源文字
 * @param fromType 付费来源类型
 */
export const download = async ( item:ResultItem, store:Store<any>, router:Router, toastOptions:ToastOptions, fromText:string = `m站-下载`, fromType:number = 50 ) => {
    try {
        // 注册下载模块
        StoreDownload.CreateUserDownload ( store ) ;
        // 是否是VIP
        if ( 1 || store.getters[`UserInfo/isVip`] ) {
            // 是VIP直接下载
            await downloadImage ( item, store, router, toastOptions ) ;
        } else {
            // 非VIP
            const browser = Bowser.parse ( window.navigator.userAgent ) ;
            if ( browser.browser.name.toLowerCase () === `wechat` ) {
                // 微信直接去付费页
                routerPush ( router, { path: `/pay/vip.html` } ) ;
            } else {
                // 获取下载次数
                await store.dispatch ( `UserDownload/FETCH_CAN_DOWNLOAD` ) ;
                // 下载过一张图就不能再下载了
                if ( !store.getters[`UserDownload/canDownload`] ) {
                    goVip ( router, fromText, fromType ) ;
                } else {
                    // 下载图片
                    await downloadImage ( item, store, router, toastOptions ) ;
                }
            }
        }
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * 下载图片
 * @param item
 * @param store
 * @param router
 * @param toastOptions
 */
export const downloadImage = async (
    item:ResultItem,
    store:Store<any>,
    router:Router,
    toastOptions:ToastOptions = {
        message: `正在下载，请查看浏览器下载进程`,
        duration: 3000,
        transition: `van-slide-up`,
        className: `favToast`,
        position: `bottom`
    } ) => {
    try {
        const url = await getDownloadImageUrl ( item,null,null, store ) ;
        const browser = Bowser.parse ( window.navigator.userAgent ) ;
        // // 获取令牌， 如果出错就报错
        // const [signErr, sign] = await store.dispatch ( `PinUser/FETCH_SIGN` ) ;
        // if ( signErr ) throw signErr ;

        // 微信浏览器使用图片查看下载
        if ( browser.browser.name.toLowerCase () === `wechat` ) {
            await routerPush ( router, { hash: `download=true` } ) ;
            setTimeout(( ) => {
                store.commit(`setOriginImageSrc`, `${url}`) ;
            }, 300)
        } else {
            Toast ( toastOptions ) ;
            downloadImg ( `${url}` ) ;
        }
        // 非VIP增加下载次数
        // if ( !store.getters[`UserInfo/isVip`] ) {
        //     await store.dispatch ( `UserDownload/FETCH_ADD_TODAY_DOWNLOAD` ) ;
        // }
    } catch ( e ) {
        throw e ;
    }
} ;

export const pinliteRoot = ():string => {
    return isSearchMobile () ? `/pinlite` : `` ;
} ;

export type statClickPinliteOptions = {
    source: string,
    // eslint-disable-next-line camelcase
    action_source: string,
    // eslint-disable-next-line camelcase
    search_word: string,
    // eslint-disable-next-line camelcase
    pin_order:number,
    // eslint-disable-next-line camelcase
    pin_action_type:string
}
/** 统计pinlite点击 */
export const statImgClickPinlite = ( store:Store<any>, options:Partial<statClickPinliteOptions> ) => {
    const params = {
        action_uid: store.state.UserInfo.id, // 操作用户的user_id
        domain: getDomain ().slice ( 1 ), // 网站域名
        group_pins: 0, // 是否组图
        action_source: store.getters.statSource, // 操作的来源
        search_word: ``, // 搜索词
        pin_action_type: `打开`,
        pin_order: 0,
        search_industry: ``, // 搜索分类   pL没分类  为空
        pin_from_page: `PL`, // 来自哪个页面
        from_page_url: window.location.href // 操作页面url
    } ;
    const args = { ...params, ...options } ;
    BigBigWork.emit ( `Analysis`, {
        type: `JhpinsHits`,
        data: args
    } ) ;
} ;

/** 统计pinlite展示 */
export const statsJhpinsImpression = ( store:Store<any>, number:number, firstOpen:string, searchWord:string ) => {
    const params = {
        list: [{
            domain: getDomain ().slice ( 1 ), // 网站域名
            group_pins: 0, // 是否组图
            pin_num: number, // 该域名下展示的图片数量
            first_open: firstOpen// 接口返回的时间
        }],
        action_uid: store.state.UserInfo.id, // 操作用户的user_id
        action_source: store.getters.statSource, // 操作的来源
        search_word: searchWord || ``, // 搜索词
        search_industry: ``, // 搜索分类   pL没分类  为空
        pin_from_page: `PL`, // 来自哪个页面
        from_page_url: window.location.href // 操作页面url
    } ;
    BigBigWork.emit ( `Analysis`, {
        type: `JhpinsImpression`,
        data: params
    } ) ;
} ;

export const handlePinDownload = async ( store:Store<any>, item:ResultItem, router:Router ) => {
    try {
        // this.statAction('下载')
        // 注册下载模块
        StoreDownload.CreateUserDownload ( store ) ;
        // statImgClickPinlite ( store, {
        //     pin_action_type: `下载`
        // } ) ;
        // 是否是VIP
        if ( store.getters[`UserInfo/isVip`] ) {
            // 是VIP直接下载
            await downloadImage ( item, store, router ) ;
        } else {
            // 非VIP
            // const browser = Bowser.parse ( window.navigator.userAgent ) ;
            const [err, res] = await getDownloadState () ;
            if (err) throw err;
            let type = null
            if ( res.k_type === 1 ) {
                let fromText = `m站` ;
                if ( store.state.isPWA ) {
                    fromText = `PWA-下载` ;
                }
                if ( store.state.isAPK ) {
                    fromText = `APK-下载` ;
                }
                goVip ( router, fromText, 54 ) ;
                return `noVip` ;
            } else if ( res.k_type === 2 ) {
                // 弹客服框A
                // type = 1 ;
            } else if ( res.k_type === 3 ) {
                // 弹客服框B
                type = 3 ;
            }
            if ( res.d_type === 0 ) {
                return type ;
            }
            await downloadImage ( item, store, router ) ;
            return type ;

            // if ( browser.browser.name.toLowerCase () === `wechat` ) {
            //     let fromText = `m站` ;
            //     if ( store.state.isPWA ) {
            //         fromText = `PWA-下载` ;
            //     }
            //     if ( store.state.isAPK ) {
            //         fromText = `APK-下载` ;
            //     }
            //     goVip ( router, fromText, 54 ) ;
            // } else {
            //     // 获取下载次数
            //     await store.dispatch ( `UserDownload/FETCH_CAN_DOWNLOAD` ) ;
            //     // 下载过一张图就不能再下载了
            //     if ( !store.getters[`UserDownload/canDownload`] ) {
            //         goVip ( router, `m站-下载`, 50 ) ;
            //     } else {
            //         // 下载图片
            //         await downloadImage ( item, store, router ) ;
            //     }
            // }
        }
    } catch ( e ) {
        throw e ;
    }
} ;

/** 返回页面统计名称 */
export const getPageName = ( route:Route ):string => {
    let pageName = route.meta?.pageName || `其他` ;
    if ( route.name === `tupian` ) {
        if ( route.query.kw ) {
            pageName = `大作搜索页` ;
        } else {
            pageName = `大作推荐页` ;
        }
    }
    return pageName;
} ;

